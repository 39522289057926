import React from 'react';
import Slider from "react-slick";
import classNames from 'classnames';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import ReviewSlide from '@components/reviews-slider/review-slide';

import './assets/styles/_index.scss';

const ReviewSlider = ({className}) => {
  const reviews = [
    {
      text: "Very pleasant guys, From putting house on the market to moving out in 3 months, excellent.",
      author: "Christopher Parkinson"
    },
    {
      text: "Starberry were far superior and they also always kept me up-to-do date with any thing they thought I needed to know.",
      author: "Jessica Smith"
    },
    {
      text: "Very professional throughout our enquiries an purchase. Ben and Chris were both exceptional.",
      author: "Alan Jenkins"
    },
    {
      text: "Starberry were far superior and they also always kept me up-to-do date with any thing they thought I needed to know.",
      author: "Jessica Smith"
    },
    {
      text: "We were very impressed with them and our house was on the market within just 24hrs and sold in the following week!",
      author: "Alan Jenkins"
    }
  ]

  return (
    <div className={classNames('reviews-slider', className)}>
      <Slider
        infinite={true}
        speed={5000}
        arrows={false}
        autoplay={true}
        centerMode={true}
        cssEase='linear'
        autoplaySpeed={0}
        variableWidth={true}
        pauseOnHover={false}
        slidesToScroll={1}
        responsive={[
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              autoplaySpeed: 500,
              variableWidth: false,
              autoplay: false,
              centerMode: false,
              cssEase: 'ease',
              speed: 1000
            }
          }
        ]}
      >
        {reviews.map((slide, ind) => <ReviewSlide key={ind} slide={slide} />)}
        
      </Slider>
      <div className="reviews">
        Reviews from <img src="/images/google.png" alt="Google"/>
      </div>
    </div>
  )
}

export default ReviewSlider;
